<template>
  <v-container class="response__container">
    <v-list-item-content></v-list-item-content>
    <b-list-group>
      <b-list-group-item>
        <div class="text-center">
          <!-- <b-alert show variant="secondary"><h5><b-badge></b-badge></h5></b-alert> -->
          <b-spinner
            label="Redirecting to status page... Please Wait..."
          ></b-spinner>
          <h6 class="mt-1">Redirecting to status page... Please Wait...</h6>
        </div>
      </b-list-group-item>
    </b-list-group>
  </v-container>
</template>

<style scoped>
.response__container {
  max-width: 55%;
}
@media screen and (max-width: 600px) {
  .response__container {
    max-width: 100%;
  }
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

Vue.filter("currency", function(value) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});
export default {
  name: "contribute",
  data() {
    return {
      panelItems: [],
      donationId: this.$route.query.razorpay_payment_link_id,
      razorpayInvoiceReceipt: this.$route.query.razorpay_payment_link_reference_id,
      contributionDetails: {},
      juspayStatus: "",
      juspayStatusDetails: "",
      juspayStatusURL: ""
    };
  },
  created: function() {
    this.paymentStatus(),
      (this.receiptId = this.$route.query.razorpay_payment_link_reference_id);
    localStorage.setItem("storedData", this.receiptId);
    setTimeout(
      () =>
        this.$router.push({
          name: "ThankYou",
          params: { donationId: this.receiptId }
        }),
      3000
    );
  },

  methods: {
    paymentStatus() {
      let orderId = `${this.donationId}`;
      
      //Receipt button Displayed only if status == "CHARGED"
      this.juspayStatusURL = this.$route.query.status;
      //Get Status from Juspay
      ApiService.get("/paymentStatus", orderId).then(({ data }) => {
        this.juspayStatus = data;
        this.juspayStatusDetails = this.juspayStatus;
        this.contributionDetails.donationId = this.juspayStatusDetails.donationId;
        this.contributionDetails.donationAmount = this.juspayStatusDetails.amount;
        this.contributionDetails.transactionId = this.juspayStatusDetails.txn_id;
      });
    },

    //Redirect to home
    home() {
      this.$router.push({ name: "contribute" });
    }
  }
};
</script>
